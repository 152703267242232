import React from 'react';
import CreatableSelect from 'react-select/creatable';
import {ActionMeta, OnChangeValue} from 'react-select';



interface Props {
    // Avoiding Complex Typing
    onChange: any
}

const CustomCreatableSelect: React.FC<Props> = (props: Props) => {
    const { onChange } = props;
    return (
        <div>
            <CreatableSelect
                isMulti
                onChange={onChange}
                options={[]}
            />
        </div>
    );
}

CustomCreatableSelect.defaultProps = {

};

export default React.memo(CustomCreatableSelect);
