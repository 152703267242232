import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import axios from "axios";
import {Formik} from "formik";
import CustomCreatableSelect from "./shared-components/components/CustomCreatableSelect"

declare global {
  interface Window {
    gonative_share_to_app(data: { url: string, subject: string }): void;
  }
}

function App() {
  const appUrl = "https://app.hypha.cloud";
  const putsReq = "https://putsreq.com/TF7cVDnwfL7vkUqT3Jj5";
  const [shareData, setShareData] = useState({})
  const [tags, setTags] = useState<string[]>()
  const [isShareFlowEnabled, setIsShareFlowEnabled] = useState(false);
  const [showSubmitMessage, setShowSubmitMessage] = useState(false);
    
  const handleTags = (data: { value: string }[]) => {
      console.log("[App.handleTags] Data Received: ", data);
      const tagsMap = (data.map((d) => d.value));
      setTags(tagsMap);
  }

  useEffect(() => {
    window.gonative_share_to_app = (data) => {
      setIsShareFlowEnabled(true)
      setShareData(data)
    }
  }, [])

  if (isShareFlowEnabled) {
    return (
      <>
        <Formik
            initialValues={{ data: JSON.stringify(shareData) }}
            onSubmit={(values) => {
              console.log("[App.onSubmit] Data Received: ", {data: values.data, tags})
              axios.put(putsReq, {data: values.data, tags})
              setShowSubmitMessage(true);  
            }}
        >
          {props => {
            return <form onSubmit={props.handleSubmit} className="grid place-items-center mt-20">
              <div className="mb-6">
                <textarea
                    onChange={props.handleChange}
                    value={props.values.data}
                    name="data"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                />
              </div>
              <div className="mb-6 max-w-xl">
                  <CustomCreatableSelect
                      onChange={handleTags}
                  ></CustomCreatableSelect>
              </div>
              <button
                  type="submit"
                  className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              >Submit
              </button>
                <p className={`font-semibold text-lime-600 ${showSubmitMessage ? "block" : "hidden"}`}>Data Submitted Successfully</p>
            </form>
          }
          }
        </Formik>
      </>
    );
  } else {
    return (
          <iframe src={appUrl} className="External-frame"></iframe>
    )
  }
}

export default App;
